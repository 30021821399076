import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    PhoneNumber: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
        custom_message: 'Please, enter valid phone number',
    },

    UUID: {
        name: 'UUID',
        required: true,
        format: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
        custom_message: 'Please, enter valid UUID',
    },
})