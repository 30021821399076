<template>
    <div id="page-allocation" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Number Allocations</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h2 class="heading" v-if="is_edit_mode">Edit Details</h2>
                        <h2 class="heading" v-else>Add Details</h2>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-input
                                    v-model="allocation.PhoneNumber"

                                    label="Phone number"

                                    :mask="phone_number_mask"

                                    :error="errors.PhoneNumber"
                                    :disabled="loading"

                                    @change="errors.PhoneNumber = null"
                                />

                                <app-dropdown-select
                                    v-model="allocation.NumberAllocationStatus"
                                    :options="number_allocation_statuses"

                                    key-value="value"
                                    key-title="title"

                                    label="Number Allocation Status"

                                    :error="errors.NumberAllocationStatus"
                                    :disabled="loading"

                                    @change="errors.NumberAllocationStatus = null"
                                />

                                <app-input
                                    v-model="allocation.PhoneNumberRangeUUID"

                                    label="Phone Number Range UUID"

                                    :error="errors.PhoneNumberRangeUUID"
                                    :disabled="loading"

                                    @change="errors.PhoneNumberRangeUUID = null"
                                />

                                <app-input
                                    v-model="allocation.CustomerUUID"

                                    label="Customer UUID"

                                    :error="errors.CustomerUUID"
                                    :disabled="loading"

                                    @change="errors.CustomerUUID = null"
                                />

                                <app-input
                                    v-model="allocation.ServiceUUID"

                                    label="Service UUID"

                                    :error="errors.ServiceUUID"
                                    :disabled="loading"

                                    @change="errors.ServiceUUID = null"
                                />
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-save" v-if="is_edit_mode" :disabled="loading || !is_changed" @click="save">Save</button>
                    <button class="btn btn-primary btn-save" v-else              :disabled="loading || !is_changed" @click="save">Add</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

import AllocationValidator from '@/validators/allocation-validator'

// import testAllocation from './test-allocation.json'

const COUNTRY_CODE = '64'

const EMPTY_ALLOCATION = {
    PhoneNumber: '',
    NumberAllocationStatus: 'NUMBER_UNKNOWN',
    PhoneNumberRangeUUID: '',
    CustomerUUID: '',
    ServiceUUID: '',
}

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            phone_number_mask: `${COUNTRY_CODE}999999999`,

            allocation: JSON.parse(JSON.stringify(EMPTY_ALLOCATION)),
            original_allocation: JSON.parse(JSON.stringify(EMPTY_ALLOCATION)),

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.is_edit_mode) {
                this.getAllocation()
            }
        },

        getAllocation() {
            this.loading = true

            /**
             * @todo
             * I am getting the error message "SPID must be a valid"
             */

            const params = {
                SPID: this.current_spid,
                UUID: this.allocation_uuid,
            }

            console.log('params', params)

            this.$store.dispatch('api_phonenumber/GetPhoneNumberByUUID', params)
                .then(response => {
                    this.original_allocation = JSON.parse(JSON.stringify(response))
                    this.allocation          = JSON.parse(JSON.stringify(response))

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        /*
            getAllocation() {
                const delay_in_ms = 1000

                this.loading = true

                setTimeout(() => {
                    this.original_allocation = JSON.parse(JSON.stringify(testAllocation))
                    this.allocation          = JSON.parse(JSON.stringify(testAllocation))

                    this.loading = false
                }, delay_in_ms)
            },
        */

        validation() {
            let is_valid = true

            this.errors = {}

            const fields = {
                PhoneNumber:          { rule: 'PhoneNumber'                                                        },
                PhoneNumberRangeUUID: { rule: 'UUID',       message: 'Please, enter valid Phone Number Range UUID' },
                CustomerUUID:         { rule: 'UUID',       message: 'Please, enter valid Customer UUID'           },
                ServiceUUID:          { rule: 'UUID',       message: 'Please, enter valid Service UUID'            },
            }

            for (const key in fields) {
                if (AllocationValidator.isRuleExists(fields[key].rule)) {
                    if (AllocationValidator.isInvalid(fields[key].rule, this.allocation[key], fields[key].message && fields[key].message)) {
                        this.errors[key] = AllocationValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        save() {
            if (this.validation()) {
                this.loading = true

                /**
                 * @todo
                 * I cannot check the method "UpdatePhoneNumber" because of an error message in the method "GetPhoneNumberByUUID"
                 */

                const action = this.is_edit_mode ? 'UpdatePhoneNumber' : 'AddPhoneNumber'

                const params = {
                    ...this.allocation,

                    SPID: this.current_spid,
                }

                console.log('params', params)

                this.$store.dispatch(`api_phonenumber/${ action }`, params)
                    .then(response => {
                        this.original_allocation = JSON.parse(JSON.stringify(response))
                        this.allocation          = JSON.parse(JSON.stringify(response))

                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        /*
            save() {
                if (this.validation()) {
                    const delay_in_ms = 1000

                    this.loading = true

                    setTimeout(() => {
                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    }, delay_in_ms)
                }
            },
        */

        reset() {
            this.errors = {}

            if (!this.is_edit_mode) {
                this.original_allocation = JSON.parse(JSON.stringify(EMPTY_ALLOCATION))
            }

            this.allocation = JSON.parse(JSON.stringify(this.original_allocation))
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'number-manager-allocations'
                ? this.referrer
                : { name: 'number-manager-allocations' }
        },

        number_allocation_statuses() {
            return [
                {
                    value: 'NUMBER_UNKNOWN',
                    title: 'NUMBER_UNKNOWN',
                },
                {
                    value: 'NUMBER_ALLOCATED_ON_NET',
                    title: 'NUMBER_ALLOCATED_ON_NET',
                },
                {
                    value: 'NUMBER_ALLOCATED_OFF_NET',
                    title: 'NUMBER_ALLOCATED_OFF_NET',
                },
                {
                    value: 'NUMBER_COOLDOWN',
                    title: 'NUMBER_COOLDOWN',
                },
                {
                    value: 'NUMBER_UNALLOCATED',
                    title: 'NUMBER_UNALLOCATED',
                },
            ]
        },

        allocation_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_edit_mode() {
            return Boolean(this.allocation_uuid)
        },

        is_changed() {
            let is_changed = false

            for (const key in EMPTY_ALLOCATION) {
                is_changed = this.allocation[key] != this.original_allocation[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.is_edit_mode) {
                this.getAllocation()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-allocation {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 30px;
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-group {
            .form-controls {
                .app-input,
                .app-dropdown-select {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    #page-allocation {
        .btn-back-to {
            margin-bottom: 16px;
        }

        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            .form-group {
                .form-controls {
                    .app-input,
                    .app-dropdown-select {
                        margin-bottom: 24px;
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>